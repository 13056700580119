.header {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .editLink {
    font-size: 0.875em;
    line-height: 130%;
    color: #ff0011;
  }
}
