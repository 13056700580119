.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 24px;

  @media (min-width: 768px) {
    gap: 40px;
  }

  @media (min-width: 1024px) {
    gap: 64px;
  }

  @media (min-width: 1280px) {
    gap: 100px;
  }

  @media (max-height: 800px) {
    gap: 24px;
  }
}
