.navigation {
  display: flex;

  &Btn {
    font-size: 0.875em;
    line-height: 1.3;
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.5rem 2.5rem;
    background: #e5e5e5;
    border-radius: 0.75rem 0.75rem 0 0;

    &Active {
      background: #ffffff;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  &Count {
    height: 1.1875rem;
    padding: 0.125rem 0.25rem;
    border-radius: 0.125rem;
    background: #ff0011;
    font-size: 0.6875rem;
    font-weight: 700;
    line-height: 150%;
    color: #ffffff;
  }
}

.cutaways {
  border-radius: 0 0 0.75em 0.75em;
  background: #ffffff;
  overflow: hidden;
}

.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4em;
  padding: 2.5em;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }

  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2.5em;
    width: 100%;
    padding: 1.5em;
    border: 1px solid #ff0011;
    border-radius: 0.75em;
  }

  &Col {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
  }

  &Title {
    font-size: 1.125em;
    line-height: 1.1;
    font-weight: 700;
  }

  &Text {
    font-size: 0.875em;
    line-height: 1.3;
    max-width: 33.8125rem;
  }

  &Link {
    color: #ff0011;
    text-decoration: underline;
    font-weight: 700;

    &:hover {
      text-decoration: none;
    }
  }

  .btn {
    padding: 0 12px;
    white-space: nowrap;
  }

  &Download {
    padding: 2.5em 2.5em 2em;
  }

  &Warning {
    padding: 2.5em 2.5em 2em;

    .messageText {
      font-size: 1em;
      line-height: 1.3;
      max-width: none;
    }
  }
}

.cutaway {
  display: grid;
  align-items: center;
  grid-gap: 2.5em;
  width: 100%;
  margin-top: 2em;
  padding: 0 2.5em 2em;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }

  &_saved {
    @extend .cutaway;
    grid-template-columns: minmax(197px, 383px) minmax(170px, 1fr) 90px;
  }

  &_outbox {
    @extend .cutaway;
    grid-template-columns: minmax(197px, 383px) minmax(90px, 1fr) 165px;
  }

  &_inbox {
    @extend .cutaway;
    grid-template-columns: minmax(197px, 383px) minmax(90px, 1fr) 165px;
  }

  figure {
    img {
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }

  p {
    margin: 0;
  }

  .persona {
    display: grid;
    grid-gap: 0.25em 1.25em;
    max-width: 15.6875em;

    @media (min-width: 1440px) {
      grid-template-columns: auto 1fr;
    }

    &Photo {
      overflow: hidden;
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;

      img {
        object-fit: cover;
      }

      @media (min-width: 1440px) {
        grid-row: 1 / span 2;
        margin: 0;
      }
    }

    &BgAvatar {
      width: 100%;
      height: 100%;
      background: #f5f5f5;
    }

    &Name {
      font-size: 0.875em;
      line-height: 1.3;
    }

    &Position {
      font-size: 0.6875em;
      line-height: 1.35;
      color: #888888;
    }
  }

  .contacts {
    display: grid;
    grid-gap: 0.5em;
  }

  .company {
    width: 5.625em;
    height: 3.75em;

    &Img {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 0.375em;
      justify-content: center;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 0.25em;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      object-position: center;
    }
  }

  .buttons {
    display: grid;
    grid-gap: 0.75em;
    padding-left: 2em;
    border-left: 1px solid #e5e5e5;

    .btn {
      width: 100%;
      min-width: auto;
      padding: 0;
      font-size: 0.875rem;
    }
  }
}
