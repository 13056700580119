.block {
  display: flex;
  flex-direction: column;
  row-gap: 2.5em;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &Content {
    display: flex;
    flex-direction: column;
  }

  &Img {
    display: none;
    max-width: 306px;
    width: 35%;

    @media (min-width: 1440px) {
      display: block;
    }
  }
}

.subtitle {
  margin-bottom: 0.75rem;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 125%;
}

.title {
  margin-bottom: 1.75rem;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 125%;

  span {
    color: #ff0011;
  }
}

.text {
  font-size: 0.875em;
  line-height: 130%;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75em;
}

.card {
  display: flex;
  column-gap: 1em;
  width: calc(50% - 0.5em);
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0.75em;
  padding: 1.25em;

  @media (min-width: 1600px) {
    width: calc(33.33% - 0.5em);
  }

  @media (min-width: 1920px) {
    column-gap: 1.25em;
    padding: 1.5em;
  }

  &Number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 2.4370rem;
    width: 2.4370rem;
    height: 2.4370rem;
    border-radius: 50%;
    background: #ff0011;
    font-weight: 800;
    font-size: 1.125em;
    line-height: 125%;
    color: #ffffff;

    @media (min-width: 1600px) and (max-width: 1919px) {
      flex: 0 0 2rem;
      width: 2rem;
      height: 2rem;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    row-gap: 0.75em;
  }

  &Title {
    font-size: 1em;
    font-weight: 700;
    line-height: 130%;
  }

  &Text {
    font-size: 0.875em;
    line-height: 130%;
  }
}

.networking {
  &Title {
    font-size: 1.5em;
    font-weight: 700;
    line-height: 125%;

    span {
      color: #ff0011;
    }
  }

  &Description {
    font-size: 1em;
    line-height: 130%;
  }

  &Footer {
    display: flex;
    flex-direction: column;
    row-gap: 0.75em;
  }

  &Note {
    color: #888888;
    font-size: 0.6875em;
    line-height: 135%;
  }
}
