.price {
  padding: 3.5em 0;
  color: $dark-grey;

  @include media-tablet {
    padding: 4em 0; }

  @include media-tablet-h {
    padding: 6.25em 0; }

  &__cont {
    display: flex;
    flex-direction: column;
    row-gap: 2em;

    @include media-tablet-h {
      row-gap: 4em; }

    @include media-desktop {
      row-gap: 5em; } }

  &__preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6.25em; }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2em;

    @include media-tablet-h {
      flex-direction: row;
      column-gap: 4.5em; } }

  &__title {
    @include reset;
    @include h24;

    @include media-tablet {
      @include h36; }

    @include media-tablet-h {
      @include h48; } }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.25em;

    @include media-tablet-h {
      gap: 2em; }

    @include media-desktop {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center; }

    &--archive {
      gap: 40px;
      flex-direction: column;
      flex-wrap: nowrap;

      @include media-tablet-h {
        flex-direction: row;
        gap: 32px; }

      .price__desc {
        @include media-tablet-h {
          width: 49.875rem; }

        @include media-desktop {
          max-width: 504px; } }


      .price__link {
        @include media-tablet {
          font-size: 1rem; }

        @include media-desktop {
          font-size: 1.125rem; } }

      .price__item {
        @include media-tablet-h {
          width: 100%; }

        @include media-desktop-1440; } } }

  &__item {
    @include media-desktop {
      width: calc(33.3% - 1.375em); } } }
