.selectedList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0 0 24px;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    column-gap: 3px;
    margin: 0;
    padding: 0;
    color: #ff0011;
    font-size: 11px;
    line-height: 1.3;
    cursor: pointer;

    &::after {
      content: "";
      width: 5px;
      height: 5px;
      background: url("@public/images/autocomplete-close.svg") no-repeat;
      background-size: contain;
    }
  }
}

.select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 18px;
    right: 20px;
    width: 12px;
    height: 6px;
    background: url("@public/images/dropdown-arrow.svg") no-repeat;
    background-size: contain;
    transition: transform 0.2s linear;
    z-index: 60;
  }

  &Show {
    &::after {
      transform: rotate(-180deg);
    }
  }

  input {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    line-height: 42px;
    padding: 0 0.8571em;
    z-index: 55;

    &:hover {
      cursor: pointer;
    }
  }

  .selector {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: 0;
    top: 21px;
    max-height: 405px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 12px 20px;
    padding-top: 33px;
    overflow-y: auto;
    background: white;
    z-index: 50;

    &Show {
      display: flex;
    }

    hr {
      border: none;
      margin: 0;
      padding: 0;
      height: 0;
      width: 100%;
      margin: 20px 0;
      border-bottom: 1px solid #c0c0c0;
    }

    ul {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 20px;
      list-style-type: none;

      li {
        display: flex;
        align-items: flex-end;
        column-gap: 8px;
        width: 100%;
        margin: 0;
        padding: 0;
        text-decoration: none;
        font-family: Proxima Nova;
        font-style: normal;
        font-size: 14px;
        line-height: 130%;
        color: #000000;
        cursor: pointer;
        transition: color 0.2s linear;

        &:hover {
          color: #ff0011;

          &::before {
            border-color: #ff0011;
            background: #ff0011;
          }
        }

        &::before {
          content: "";
          width: 19px;
          height: 19px;
          border: 1px solid #c0c0c0;
          border-radius: 4px;
          transition: border, background 0.2s linear;
        }
      }

      .selected {
        color: #ff0011;

        &::before {
          border-color: #ff0011;
          background: #ff0011;
        }
      }
    }

    &NoBefore {
      ul li {
        &::before {
          content: none;
        }
      }
    }
  }
}
