.title {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 120%;
  margin-bottom: 40;
}

.grid {
  display: block;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.6875rem;
    row-gap: 1.6875rem;
  }
}

.block {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  @media (min-width: 768px) {
    margin: 0;
  }
  .filename {
    margin-top: 21px;
    margin-bottom: 24px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 125%;
    color: #191919;
  }
  .downloadBtn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    border: 1px solid #c0c0c0;
    box-sizing: border-box;
    display: block;
    border-radius: 4px;
    padding: 9px 54px;
    width: min-content;
    white-space: nowrap;
  }
}
