.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &__chevron {
    &::after {
      content: "";
      position: absolute;
      top: 18px;
      right: 20px;
      width: 12px;
      height: 6px;
      background: url("@public/images/dropdown-arrow.svg") no-repeat;
      background-size: contain;
      transition: transform 0.2s linear;
      z-index: 1000;
    }

    &Show {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  input {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    line-height: 42px;
    padding: 0 20px;
    z-index: 100;

    &:hover {
      cursor: pointer;
    }
  }

  .selector {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: 0;
    top: 21px;
    max-height: 405px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 12px 20px;
    padding-top: 33px;
    overflow-y: auto;
    background: white;
    z-index: 79;

    &Show {
      display: flex;
    }

    hr {
      border: none;
      margin: 0;
      padding: 0;
      height: 0;
      width: 100%;
      margin: 20px 0;
      border-bottom: 1px solid #c0c0c0;
    }

    ul {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 20px;
      list-style-type: none;

      li {
        margin: 0;
        padding: 0;

        a {
          display: flex;
          width: 100%;
          text-decoration: none;
          font-family: Proxima Nova;
          font-style: normal;
          font-size: 14px;
          line-height: 130%;
          color: #000000;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      &.priority {
        li {
          a {
            font-weight: 800;
          }
        }
      }
    }
  }
}
