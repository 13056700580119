.rating {
  margin: 0;
  list-style: none;
}

.item {
  display: grid;
  grid-template-columns: 7.5em 1fr 4.5625em;
  column-gap: 2em;
  align-items: center;

  &:not(:first-child) {
    padding-top: 1.5em;
  }

  &:not(:last-child) {
    padding-bottom: 1.5em;
    border-bottom: 0.0625em solid #e5e5e5;
  }

  @media (min-width: 1600px) {
    column-gap: 3.5em;
  }

  &Date {
    font-size: 0.875em;
    line-height: 1.3;
    color: #888888;
  }

  &Content {
    display: grid;
    row-gap: 0.25em;
  }

  &Desc {
    font-size: 0.875em;
    line-height: 1.3;
  }

  &Count {
    font-size: 1.5em;
    line-height: 1.25;
    font-weight: 700;
    color: #ff0011;
    text-align: right;
  }
}

.loyalty {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 2.5em;
  padding: 3em 2em 2em;

  @media (min-width: 1600px) {
    padding: 3.75em 2.5em 2em;
  }

  &Logo {
    display: flex;
    justify-content: center;
  }

  &List {
    display: flex;
    flex-direction: column;
    row-gap: 1.25em;
  }

  &Item {
    display: flex;
    column-gap: 1em;
    align-items: center;
    justify-content: space-between;

    &Title {
      font-size: 1em;
      line-height: 1.3;
    }

    &Score {
      font-size: 1.5em;
      line-height: 1.25;
      font-weight: 700;
    }
  }
}
