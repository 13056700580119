.block {
  display: flex;
  flex-direction: column;
  row-gap: 2.5em;
}

.title {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 125%;

  span {
    color: #ff0011;
  }
}

.description {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  font-size: 1em;
  line-height: 130%;
}

.footer {
  display: flex;
  flex-direction: column;
  row-gap: 0.75em;
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.note {
  color: #888888;
  font-size: 0.6875em;
  line-height: 135%;
}
