.block {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 12px;
  background: #ffffff;

  @media (min-width: 1600) {
    padding: 40px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 800;
      font-size: 24px;
      line-height: 125%;
      color: #191919;
    }

    .showLink {
      font-weight: normal;
      font-size: 14px;
      line-height: 155%;
      color: #191919;

      &.hideLink {
        transform: rotate(180deg);
      }
    }
  }

  .description {
    margin: 40px 0;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 155%;
    color: #191919;
  }

  hr {
    margin: 24px 0;
    border: none;
    border-bottom: 1px solid #c0c0c0;
  }

  .footer {
    margin-top: 24px;
  }
}
